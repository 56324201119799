/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Page, SEO, Container, Heading } from '../../components';
import { MainNavigation } from '../../compositions';
import { Content, Header, Date, Figure } from './BlogPost.style';

const BlogPost = ({ data }) => {
  const [article] = data.allContentfulArticle.edges;
  const content = article.node;
  const { publishDate, title, image, body } = content;

  return (
    <Page>
      <SEO title={`${title} | Virtual Refugee Conference`} />
      <MainNavigation />
      <Container>
        <Content>
          <Header>
            <Date>{publishDate}</Date>
            <Heading element="h1" size="h2">
              {title}
            </Heading>
          </Header>
          <Figure>
            <Img alt={image.description} fluid={image.fluid} />
          </Figure>
          {documentToReactComponents(body.json)}
        </Content>
      </Container>
    </Page>
  );
};

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    allContentfulArticle(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          publishDate(formatString: "MM[-]DD[-]YYYY")
          title
          image {
            description
            fluid(maxWidth: 1100) {
              src
              srcSet
              sizes
              base64
              aspectRatio
            }
          }
          body {
            json
          }
        }
      }
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPost;
