import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem } from '../../utils/utils';

const contentStyles = ({ theme: { color } }) => css`
  text-align: left;

  li p {
    margin: 0;
  }

  a {
    color: ${color.black};
    text-decoration: underline;
    &:hover,
    &:active {
      color: ${color.black};
    }
  }
`;

const headerStyles = css`
  text-align: center;
  margin: 50px 0;
`;

const dateStyles = ({ theme: { color, font } }) => css`
  font-family: ${font.display};
  font-size: ${rem(14)};
  color: ${color.blueGray600};
`;

const figureStyles = css`
  margin: 0 0 30px;
`;

/**
 * Component style declarations
 */
export const Content = styled('section')(contentStyles);
export const Header = styled('header')(headerStyles);
export const Date = styled('em')(dateStyles);
export const Figure = styled('figure')(figureStyles);
